import moment from 'moment'

export const projectName= "Goel Ganga"
export const serverErrorMessage= "Something Went Wrong!"

export const custTime= "9 AM"

export const selectableTimes= ["9 AM", "10 AM", "11 AM", "12 PM", "1 PM", "2 PM", "3 PM", "4 PM", "5 PM", "6 PM", "7 PM", "8 PM", "9 PM"]

export const scheduleConfUrl = 'public_api/leads'
export const bookingUrl = 'public/bookings/new'
export const userTypeUrl = 'user_type'
export const enquiryFormUrl = "customer"

export const paymentPlans = ['CLP', 'EMI Scheme', 'EMI+CLP', 'Subvention 1:99', 'Subvention 10:90']

export function confCallParams(callParams){
  let toSend = {}
  toSend['name'] = callParams.custName
  toSend['email'] = callParams.custEmail
  toSend['phone'] = callParams.custPhone
  toSend['date'] = callParams.custDate ? moment(callParams.custDate).format("DD-MM-YYYY") : null
  toSend['time'] = callParams.custTime
  toSend['project'] = projectName
  return toSend
}

export function santizeBookingParams(bParams){
  let toSend = {}
  if(bParams.leadNo){
    toSend['ln'] = bParams.leadNo
  }else{
    toSend['p'] = projectName
  }
  toSend['t'] = bParams.typologyName
  toSend['f'] = bParams.floorName
  toSend['in'] = bParams.flatName
  toSend['wi'] = bParams.wingName
  toSend['pn'] = bParams.selectedPlan
  toSend['cpuu'] = bParams.cpId
  return toSend
}

export const masterJson = 
{
  "Buildings" : [
    {
      "uuid": "1",
      "key": "Wing 1",
      "position": "left",
      "isWing": true,
      "value": "2BHK-Type 1, 2BHK-Type 2, 2BHK-Type 3, 3BHK",
      "status": "Available",
      "opacity": "0.01",
      "fillOpacity":"0.01",
      "fill": "#0075C4",
      "floor": "28",
      "path": "M616 289.5L616 300H612L612 314.5L624 314.5L624 306H632.637V313.538L645 313.538L645 300H641.5L641.5 289L644 289L644 279.5L635.5 279.5L635.5 277L631 277L631 288.5H626V277L622 277L622 279.5L612.5 279.5V289.5L616 289.5Z"
    },
    {
      "uuid": "2",
      "key": "Wing 2",
      "position": "left",
      "value": "2BHK-Type 1, 2BHK-Type 2, 2BHK-Type 3, 3BHK",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0075C4",
      "fillOpacity":"0.01",
      "floor": "28",
      "isWing": true,
      "path": "M616 265L616 254.5H612L612 240L624 240L624 248.5H632.637V228L645 228L645 254.5H641.5L641.5 265.5L644 265.5L644 275L635.5 275L635.5 277.5L631 277.5L631 266H626V277.5L622 277.5L622 275L612.5 275V265L616 265Z"
    },
    {
      "uuid": "3",
      "key": "Wing 3",
      "position": "left",
      "value": "2BHK-Type 1, 2BHK-Type 2, 2BHK-Type 3, 3BHK",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0075C4",
      "fillOpacity":"0.01",
      "isWing": true,
      "floor": "28",
      "path": "M594 216.5L594 227H590L590 241.5L602 241.5L602 233H612.5V240.538L623 240.538L623 227H619.5L619.5 216L622 216V205L618 205L613 205V211H609H605.5H601.5V203H590.5V206.5V216.5L594 216.5Z"
    },
    {
      "uuid": "4",
      "key": "Wing 4",
      "position": "left",
      "value": "2BHK-Type 1, 2BHK-Type 2, 2BHK-Type 3, 3BHK",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0075C4",
      "fillOpacity":"0.01",
      "isWing": true,
      "floor": "28",
      "path": "M616 179.5L616 190H612L612 204.5L624 204.5L624 196H632.637V216L645 216L645 190H641.5L641.5 179L644 179L644 169.5L635.5 169.5L635.5 167L631 167L631 178.5H626V167L622 167L622 169.5L612.5 169.5V179.5L616 179.5Z"
    },
    {
      "uuid": "5",
      "key": "Wing 5",
      "position": "left",
      "value": "2BHK-Type 1, 2BHK-Type 2, 2BHK-Type 3, 3BHK",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0075C4",
      "fillOpacity":"0.01",
      "isWing": true,
      "floor": "28",
      "path": "M616 155L616 144.5H612L612 130L624 130L624 138.5H632.637V130.962L645 130.962L645 144.5H641.5L641.5 155.5L644 155.5L644 165L635.5 165L635.5 167.5L631 167.5L631 156H626V167.5L622 167.5L622 165L612.5 165V155L616 155Z"
    },
    {
      "uuid": "6",
      "key": "Wing 6",
      "position": "left",
      "value": "2BHK-Type 1, 2BHK-Type 2, 2BHK-Type 3, 3BHK",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0075C4",
      "fillOpacity":"0.01",
      "isWing": true,
      "floor": "28",
      "path": "M607.675 101L597.312 101L597.312 98L583 98.0003L583 111.5L591.39 111.5L591.39 117L583.949 117L583.949 131L597.312 131L597.312 127.5L608.169 127.5L608.169 130L619.026 130L619.026 126L619.026 121L613.104 121L613.104 117L613.104 113.5L613.104 109.5L621 109.5L621 98.5002L617.546 98.5002L607.675 98.5002L607.675 101Z"
    },
    {
      "uuid": "7",
      "key": "Wing 7",
      "position": "left",
      "value": "2BHK-Type 1, 2BHK-Type 2, 2BHK-Type 3, 3BHK",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0075C4",
      "fillOpacity":"0.01",
      "isWing": true,
      "floor": "28",
      "path": "M570.675 123L560.312 123L560.312 120L546 120L546 133.5L554.39 133.5L554.39 139L546.949 139L546.949 153L560.312 153L560.312 149.5L571.169 149.5L571.169 152L582.026 152L582.026 148L582.026 143L576.104 143L576.104 139L576.104 135.5L576.104 131.5L584 131.5L584 120.5L580.546 120.5L570.675 120.5L570.675 123Z"
    },
    {
      "uuid": "8",
      "key": "Wing 8",
      "position": "right",
      "value": "2BHK-Type 1, 2BHK-Type 2, 2BHK-Type 3, 3BHK",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0075C4",
      "fillOpacity":"0.01",
      "isWing": true,
      "floor": "28",
      "path": "M488.325 123L498.688 123L498.688 120L513 120L513 133.5L504.61 133.5L504.61 139L512.051 139L512.051 153L498.688 153L498.688 149.5L487.831 149.5L487.831 152L476.974 152L476.974 148L476.974 143L482.896 143L482.896 139L482.896 135.5L482.896 131.5L475 131.5L475 120.5L478.454 120.5L488.325 120.5L488.325 123Z"
    },
    {
      "uuid": "9",
      "key": "Wing 9",
      "position": "right",
      "value": "2BHK-Type 1, 2BHK-Type 2, 2BHK-Type 3, 3BHK",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0075C4",
      "fillOpacity":"0.01",
      "isWing": true,
      "floor": "28",
      "path": "M451.325 101L461.688 101L461.688 98L476 98.0003L476 111.5L467.61 111.5L467.61 117L475.051 117L475.051 131L461.688 131L461.688 127.5L450.831 127.5L450.831 130L439.974 130L439.974 126L439.974 121L445.896 121L445.896 117L445.896 113.5L445.896 109.5L438 109.5L438 98.5002L441.454 98.5002L451.325 98.5002L451.325 101Z"
    },
    {
      "uuid": "10",
      "key": "Wing 10",
      "position": "right",
      "value": "2BHK-Type 1, 2BHK-Type 2, 2BHK-Type 3, 3BHK",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0075C4",
      "fillOpacity":"0.01",
      "isWing": true,
      "floor": "28",
      "path": "M443 155L443 144.5H447L447 130L435 130L435 138.5H426.363V130.962L414 130.962L414 144.5H417.5L417.5 155.5L415 155.5L415 165L423.5 165L423.5 167.5L428 167.5L428 156H433V167.5L437 167.5L437 165L446.5 165V155L443 155Z"
    },
    {
      "uuid": "11",
      "key": "Wing 11",
      "position": "right",
      "value": "2BHK-Type 1, 2BHK-Type 2, 2BHK-Type 3, 3BHK",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0075C4",
      "fillOpacity":"0.01",
      "isWing": true,
      "floor": "28",
      "path": "M443 179.5L443 190H447L447 204.5L435 204.5L435 196H426.363V216L414 216L414 190H417.5L417.5 179L415 179L415 169.5L423.5 169.5L423.5 167L428 167L428 178.5H433V167L437 167L437 169.5L446.5 169.5V179.5L443 179.5Z"
    },
    {
      "uuid": "12",
      "key": "Wing 12",
      "position": "right",
      "value": "2BHK-Type 1, 2BHK-Type 2, 2BHK-Type 3, 3BHK",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0075C4",
      "fillOpacity":"0.01",
      "isWing": true,
      "floor": "28",
      "path": "M465 216.5L465 227H469L469 241.5L457 241.5L457 233H446.5V240.538L436 240.538L436 227H439.5L439.5 216L437 216V205L441 205L446 205V211H450H453.5H457.5V203H468.5V206.5V216.5L465 216.5Z"
    },
    {
      "uuid": "13",
      "key": "Wing 13",
      "position": "right",
      "value": "2BHK-Type 1, 2BHK-Type 2, 2BHK-Type 3, 3BHK",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0075C4",
      "fillOpacity":"0.01",
      "isWing": true,
      "floor": "28",
      "path": "M443 265L443 254.5H447L447 240L435 240L435 248.5H426.363V228L414 228L414 254.5H417.5L417.5 265.5L415 265.5L415 275L423.5 275L423.5 277.5L428 277.5L428 266H433V277.5L437 277.5L437 275L446.5 275V265L443 265Z"
    },
    {
      "uuid": "14",
      "key": "Wing 14",
      "position": "right",
      "value": "2BHK-Type 1, 2BHK-Type 2, 2BHK-Type 3, 3BHK",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0075C4",
      "fillOpacity":"0.01",
      "isWing": true,
      "floor": "28",
      "path": "M443 289.5L443 300H447L447 314.5L435 314.5L435 306H426.363V313.538L414 313.538L414 300H417.5L417.5 289L415 289L415 279.5L423.5 279.5L423.5 277L428 277L428 288.5H433V277L437 277L437 279.5L446.5 279.5V289.5L443 289.5Z"
    },
    {
      "uuid": "15",
      "key": "Wing 15",
      "position": "right",
      "value": "2BHK-Type 1, 2BHK-Type 2, 2BHK-Type 3, 3BHK",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0075C4",
      "fillOpacity":"0.01",
      "isWing": true,
      "floor": "28",
      "path": "M91.5 197L113.5 203L112.5 207L120 208.5L121.5 204L144 210.5L140.5 222.5L146.5 224.5L149.5 211.5L171.5 217.5L168.5 229.5L157 227L155.5 232L167 235.5L164.5 246L142.5 240L145 228.5L139 227L136 239L113.5 233.5L116.5 220.5L110 219L106.5 231L84.5 225L87.5 214L100 217.5L101.5 212.5L88.5 209L91.5 197Z"
    },
    {
      "uuid": "16",
      "key": "Wing 16",
      "position": "right",
      "value": "2BHK-Type 1, 2BHK-Type 2, 2BHK-Type 3, 3BHK",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0075C4",
      "fillOpacity":"0.01",
      "isWing": true,
      "floor": "28",
      "path": "M50 123L72 129L71 133L78.5 134.5L80 130L102.5 136.5L99 148.5L105 150.5L108 137.5L130 143.5L127 155.5L115.5 153L114 158L125.5 161.5L123 172L101 166L103.5 154.5L97.5 153L94.5 165L72 159.5L75 146.5L68.5 145L65 157L43 151L46 140L58.5 143.5L60 138.5L47 135L50 123Z"
    },
    {
      "key": "Park",
      "value": "Park",
      "status": "Available",
      "opacity": "0.01",
      "isWing": false,
      "fill": "#0075C4",
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.biz.images/Goel_Ganga_Asmi_EOI_park-.png",
      "path": ""
    },
    {
      "key": "Play Area",
      "value": "Play Area",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0075C4",
      "isWing": false,
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.biz.images/Goel_Ganga_Asmi_EOI_playground.png",
      "path": ""
    },
    {
      "key": "Mariegold Clubhouse",
      "value": "Mariegold Clubhouse",
      "status": "na",
      "opacity": "0.01",
      "fill": "#0267C1",
      "isWing": false,
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/nirvana/clubhouse.jpg",
      "path": ""
    },
    {
      "key": "Minigolf Course",
      "value": "Minigolf Course",
      "status": "na",
      "opacity": "0.01",
      "fill": "#0267C1",
      "isWing": false,
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/nirvana/minigolf.jpg",
      "path": ""
    },
    {
      "key": "Gymnasium",
      "value": "Gymnasium",
      "status": "na",
      "opacity": "0.01",
      "fill": "#0267C1",
      "isWing": false,
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/nirvana/gym.jpg",
      "path": ""
    },
    {
      "key": "Multi Cuisine Restaurant",
      "value": "Multi Cuisine Restaurant",
      "status": "na",
      "opacity": "0.01",
      "fill": "#0267C1",
      "isWing": false,
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/nirvana/restaurant.jpg",
      "path": ""
    },
    {
      "key": "Swimming Pool",
      "value": "Swimming Pool",
      "status": "na",
      "opacity": "0.01",
      "fill": "#0267C1",
      "isWing": false,
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/nirvana/pool.jpg",
      "path": ""
    },
    {
      "key": "Kids Club",
      "value": "Kids Club",
      "status": "na",
      "opacity": "0.01",
      "fill": "#0267C1",
      "isWing": false,
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/nirvana/kidsclub.jpg",
      "path": ""
    },
    {
      "key": "Butterfly Flower Garden",
      "value": "Butterfly Flower Garden",
      "status": "na",
      "opacity": "0.01",
      "fill": "#0267C1",
      "isWing": false,
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/nirvana/flowergarden.jpg",
      "path": ""
    },
    {
      "key": "Daisy Dome Greenhouse",
      "value": "Daisy Dome Greenhouse",
      "status": "na",
      "opacity": "0.01",
      "fill": "#0267C1",
      "isWing": false,
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/nirvana/greenhouse.jpg",
      "path": ""
    },
    {
      "key": "Botanical Garden",
      "value": "Botanical Garden",
      "status": "na",
      "opacity": "0.01",
      "fill": "#0267C1",
      "isWing": false,
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/nirvana/botanicalgarden.jpg",
      "path": ""
    },
    {
      "key": "Meditation Center",
      "value": "Meditation Center",
      "status": "na",
      "opacity": "0.01",
      "fill": "#0267C1",
      "isWing": false,
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/nirvana/meditation.jpg",
      "path": ""
    },
    {
      "key": "Lily Pond",
      "value": "Lily Pond",
      "status": "na",
      "opacity": "0.01",
      "fill": "#0267C1",
      "isWing": false,
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/nirvana/lilypond.jpg",
      "path": ""
    }

  ],
  "Variants" : [
    {
      "uuid": "1",
      "key": "floorseta",
      "value": "2BHK - Type 1",
      "price":"75 - 77L",
      "area":"692",
      "configuration":"2BHK",
      "TwoDImg": "https://s3.ap-south-1.amazonaws.com/enrichr.biz.images/ganga-goel+/2D-2BHK-t1.png",
      "ThreeDImg": "https://s3.ap-south-1.amazonaws.com/enrichr.biz.images/ganga-goel+/3D-2BHK-t1.png",
      "paths":[{
        "path":"M280 181.5H227V65.5H382.5V181.5H330V201H280V181.5Z",
        "fill":"#0075C4",
        "opacity":"0.01",
        "fillOpacity":"0.02"
      },{
        "path":"M434.5 182H383V65H538V182H484.5V201H434.5V182Z",
        "fill":"#0075C4",
        "opacity":"0.01",
        "fillOpacity":"0.02",
      },{
        "path":"M589 180H539V64.5H693V180H639.5V201.5H589V180Z",
        "fill":"#0075C4",
        "opacity":"0.01",
        "fillOpacity":"0.02",
      },{
        "path":"M227 452.5V336H280.5V316.5H329.5V339H355.5V354H382.5V452.5H227Z",
        "fill":"#0075C4",
        "opacity":"0.01",
        "fillOpacity":"0.02",
      },{
        "path":"M383 454V337H435.5V317H485V340H511.5V355H537V454H383Z",
        "fill":"#0075C4",
        "opacity":"0.01",
        "fillOpacity":"0.02",
      },{
        "path":"M692.5 453V336H639V317H590V339H564V354.5H537V453H692.5Z",
        "fill":"#0075C4",
        "opacity":"0.01",
        "fillOpacity":"0.02",
      }]
    },
    {
      "uuid": "2",
      "key": "floorsetb",
      "value": "2BHK - Type 2",
      "price": "79 - 81L",
      "area": "719",
      "configuration":"2BHK",
      "TwoDImg": "https://s3.ap-south-1.amazonaws.com/enrichr.biz.images/ganga-goel+/2D-2BHK-t2.png",
      "ThreeDImg": "https://s3.ap-south-1.amazonaws.com/enrichr.biz.images/ganga-goel+/3D-2BHK-t2.png",
      "paths":[{
        "path":"M173.5 361H201V336H189.5V276H120.5V338.5H43V452.5H97.5V443H126V452.5H173.5V361Z",
        "fill":"#0075C4",
        "opacity":"0.01",
        "fillOpacity":"0.02",
      }],
    },
    {
      "uuid": "3",
      "key": "floorsetc",
      "value": "2BHK - Type 3",
      "price": "85 - 87L",
      "area": "779",
      "configuration":"2BHK",
      "TwoDImg": "https://s3.ap-south-1.amazonaws.com/enrichr.biz.images/ganga-goel+/2D-2BHK-t3.png",
      "ThreeDImg": "https://s3.ap-south-1.amazonaws.com/enrichr.biz.images/ganga-goel+/3D-2BHK-t3.png",
      "paths": [{
        "path":"M693 453V335H715V338.5H876.5V453H821.5V442.5H794V453H693Z",
        "fill":"#0075C4",
        "opacity":"0.01",
        "fillOpacity":"0.02",
      }]
    },
    {
      "uuid": "4",
      "key": "floorsetd",
      "value": "3BHK",
      "price": "95 - 97L",
      "area": "903",
      "configuration":"3BHK",
      "TwoDImg": "https://s3.ap-south-1.amazonaws.com/enrichr.biz.images/ganga-goel+/2D-3BHK.png",
      "ThreeDImg": "https://s3.ap-south-1.amazonaws.com/enrichr.biz.images/ganga-goel+/3D-3BHK.png",
      "paths":[{
        "path":"M227 64V181.5H144V201H44V110H17V64H99V75.5H126V64H227Z",
        "fill":"#0075C4",
        "opacity":"0.01",
        "fillOpacity":"0.02",
      },{
        "path":"M776.5 180.5H693V66.5H794.5V75H821.5V66.5H902.5V110H875.5V201.5H776.5V180.5Z",
        "fill":"#0075C4",
        "opacity":"0.01",
        "fillOpacity":"0.02",
      }]
    }
  ],
}

export const FormSteps = ["Explore Project","Explore Master layout","Select Variant","Enter Details","KYC & Make Payment"]